//OffCampus.js

import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
    Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Button, IconButton, Autocomplete, Modal, Box, Typography, Alert, CircularProgress, Snackbar
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add'
import { format } from 'date-fns';
import { formatTime24To12, formatDate, authenticatedFetch } from '../utils/utils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const OffCampus = ({ isAdmin }) => {
    const params = useParams();

    const [date, setDate] = useState(new Date());
    const [offCampusLogs, setOffCampusLogs] = useState([]);
    const [eligibleStudents, setEligibleStudents] = useState([]);
    const [formData, setFormData] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [logToDelete, setLogToDelete] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [destinations, setDestinations] = useState([]);
    const [dailyAttendance, setDailyAttendance] = useState({});

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        paddingTop: 6,
    };

    useEffect(() => {
        const fetchOffCampusLogs = async () => {
            const response = await authenticatedFetch(`/off-campus/daily/${formatDate(date)}`);
            if (response.ok) {
                const logs = await response.json();
                setOffCampusLogs(logs);
            } else {
                throw new Error('Failed to fetch off-campus logs');
            }
        };

        const fetchEligibleStudents = async () => {
            const response = await authenticatedFetch(`/students/active-school-meeting-members`);
            if (response.ok) {
                const students = await response.json();
                setEligibleStudents(students);
            } else {
                throw new Error('Failed to fetch eligible students');
            }
        };


        const fetchData = async () => {
            setLoading(true);
            try {
                await fetchOffCampusLogs();
                await fetchEligibleStudents();
            }
            catch (error) {
                setAlert({ message: 'Failed to fetch data' });
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [date]);

    useEffect(() => {
        if (params.date) {
            const dateFromUrl = new Date(params.date);
            setDate(dateFromUrl);
        }
    }, [params.date]);

    useEffect(() => {
        if (!isAdmin) {
            const msUntilTomorrow = () => {
                const now = new Date();
                const tomorrow = new Date(now);
                tomorrow.setHours(25, 0, 0, 0);
                return tomorrow.getTime() - now.getTime();
            };

            const updateToDate = () => {
                window.location.reload();
            };

            const timeout = setTimeout(updateToDate, msUntilTomorrow());

            return () => clearTimeout(timeout);
        }
    }, [isAdmin]);

    useEffect(() => {
        // Fetch destinations
        authenticatedFetch('/off-campus-destinations')
            .then(response => response.json())
            .then(data => setDestinations(data))
            .catch(error => console.error('Error fetching destinations:', error));
    }, []); // Empty dependency array to run only once on component mount

    useEffect(() => {
        const fetchDailyAttendance = async () => {
            try {
                const response = await authenticatedFetch(`/attendance/daily/${formatDate(date)}`);
                if (response.ok) {
                    const records = await response.json();
                    const attendanceMap = records.reduce((acc, record) => {
                        acc[record.student_id] = record;
                        return acc;
                    }, {});
                    setDailyAttendance(attendanceMap);
                } else {
                    throw new Error('Failed to fetch daily attendance');
                }
            } catch (error) {
                console.error('Error fetching daily attendance:', error);
                setAlert({ message: 'Failed to fetch daily attendance', severity: 'error' });
            }
        };

        fetchDailyAttendance();
    }, [date]);

    const handleEdit = (log) => {
        if (!isAdmin && log.signed_in_at) return;

        const groupLogs = offCampusLogs.filter(otherLog => log.destination === otherLog.destination && log.return_expected_at === otherLog.return_expected_at);
        let formData = {
            ...log,
            destination: log.destination.split(','),
            ids: groupLogs.map(log => log.id),
            student_ids: groupLogs.map(log => log.student_id),
            isAddingStudents: false
        };

        if (!isAdmin) {
            formData.signed_in_at = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
        }

        setFormData(formData);
    };

    const handleAddNewLog = () => {
        setFormErrors({})

        setFormData({
            student_ids: '',
            date: formatDate(date),
            signed_out_at: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
            signed_in_at: '',
            return_expected_at: '',
            destination: [],
            phone_number: '',
            grant_hours: false
        });
    };

    const handleSave = async () => {
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length > 0) return;

        if (formData.isAddingStudents) {
            formData.signed_in_at = '';
        }
        if (!isAdmin && formData.destination.find(d => d.includes('Doctor'))) {
            formData.grant_hours = true;
        }

        const saveLog = async (log) => {
            const url = `/off-campus/log`;
            const method = log.id ? 'PUT' : 'POST';

            try {
                const response = await authenticatedFetch(url, {
                    method: method,
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(log)
                });

                if (!response.ok) {
                    throw new Error('Failed to save off-campus log for student ID: ' + log.student_id);
                }
            } catch (error) {
                throw error;
            }
        };

        try {
            setLoading(true);

            let logs;
            if (formData.id) {
                logs = formData.student_ids.map(id => {
                    const log = offCampusLogs.find(log => log.student_id === id);

                    return {
                        ...formData,
                        student_id: id,
                        id: log && log.id,
                        destination: formData.destination.join(",")
                    }
                });
            }
            else {
                logs = formData.student_ids.map(id => ({
                    ...formData,
                    student_id: id,
                    destination: formData.destination.join(",")
                }));
            }

            await Promise.all(logs.map(saveLog));
            let message;
            if (isAdmin) {
                message = 'Off-Campus record saved successfully';
            }
            else {
                message = formData.signed_in_at ? 'Signed in successfully' : 'Signed out successfully';
            }
            setAlert({
                message: message,
                severity: 'success',
                autoHideDuration: 3000
            });
            setFormData(null); // Clear the form data to close the modal
            setDate(new Date(date)); // Force a re-render to update the table
        } catch (error) {
            setAlert({ message: error.message });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await authenticatedFetch(`/off-campus/log/${logToDelete}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                setOffCampusLogs(logs => logs.filter(log => log.id !== logToDelete));
                setDate(new Date(date)); // Force a re-render to update the table
            } else {
                throw new Error('Failed to delete off-campus log');
            }
        } catch (error) {
            setAlert({ message: 'Failed to delete off-campus log' });
            console.error('Error deleting off-campus log:', error);
        }
        finally {
            setLoading(false);
            setDeleteDialogOpen(false);
            setLogToDelete(null);
        }
    };

    const handleCancel = () => {
        setFormData(null);
    };

    const handleDeleteConfirmation = (logId) => {
        setFormData(null);
        setLogToDelete(logId);
        setDeleteDialogOpen(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        setOpenDatePicker(false);
        setDate(date);
    };

    const handleDestinationChange = (event, newValue, reason) => {
        // Check if the reason for the change is a selection from the dropdown or free solo input
        if (reason === 'selectOption' || reason === 'createOption') {
            // If it's a selection from the dropdown or free solo input, update as is
            setFormData(prevFormData => ({
                ...prevFormData,
                destination: newValue
            }));
        } else if (reason === 'removeOption') {
            // If an option is removed, update the state accordingly
            setFormData(prevFormData => ({
                ...prevFormData,
                destination: newValue
            }));
        }
    };

    const handlePhoneNumberChange = (event, newValue, reason) => {
            // Check if newValue is an object or a string
        // If it's an object, it comes from a selection; use its phone_number property
        // If it's a string, it's a free-form entry; use the string directly
        const isObject = (newValue && typeof newValue === 'object');
        const phoneNumber = isObject ? newValue.phone_number : newValue;
        setFormData(prevFormData => ({
            ...prevFormData,
            phone_number: phoneNumber || '' // Ensure fallback to empty string if undefined
        }));
    };

    const handleStudentsChange = (event, newValue) => {
        const student_ids = newValue.map(student => student.id);
        const isAddingStudents = formData.id && formData.student_ids !== student_ids && !isAdmin;

        setFormData(prevFormData => {
            // If the phone number field is empty, set it to the first selected person with a phone number
            let phone_number = prevFormData.phone_number;
            if (!phone_number && newValue.length > 0) {
                const studentWithPhoneNumber = newValue.find(student => student.phone_number);
                phone_number = studentWithPhoneNumber && studentWithPhoneNumber.phone_number;
            }
            return {
                ...prevFormData,
                student_ids: student_ids,
                phone_number: phone_number,
                isAddingStudents: isAddingStudents
            };
        });
    }

    const validateForm = () => {
        const errors = {};
        if (!formData.student_ids || formData.student_ids.length === 0) errors.student_ids = "Select one or more people";
        if (!formData.destination || formData.destination.length === 0) errors.destination = "Destination is required";
        if (!formData.phone_number) errors.phone_number = "Phone number is required";
        if (!formData.signed_out_at) errors.signed_out_at = "Left At time is required";
        if (!formData.return_expected_at) errors.return_expected_at = "Return ETA is required";
        if (formData.return_expected_at && formData.return_expected_at < formData.signed_out_at) errors.return_expected_at = "Return ETA should be after your sign-out time.";
        if (formData.signed_in_at && formData.signed_in_at < formData.signed_out_at) errors.signed_in_at = "Returned at Time should be after Left At time";

        // Check if any selected students are not present
        const absentStudents = (formData.student_ids || []).map(id => eligibleStudents.find(student => student.id === id)).filter(student => {
            const attendanceRecord = dailyAttendance[student.id];
            return !attendanceRecord || !attendanceRecord.signed_in_at || attendanceRecord.absence_reason !== 'present';
        }).filter(student => !student.is_staff);

        if (absentStudents.length > 0) {
            const absentNames = absentStudents.map(student => student.nickname).join(', ');
            errors.student_ids = `The following student(s) must be signed in and present at school to go off campus: ${absentNames}`;
        }

        return errors;
    };

    return (
        <>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            )}
            <Box sx={{ display: 'flex', marginBottom: 2 }}>
                <Typography variant="h5" gutterBottom>
                    {format(date, 'eeee, MMMM do, yyyy')}
                </Typography>
                {isAdmin && (<Button sx={{ mb: 2 }} onClick={() => setOpenDatePicker(true)}>Change Day</Button>)}

                {openDatePicker && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            open={openDatePicker}
                            value={date}
                            onChange={handleDateChange}
                            onClose={() => setOpenDatePicker(false)}
                            views={['day']}
                            shouldDisableDate={(date) => [0, 6].includes(date.getDay())}
                        />
                    </LocalizationProvider>
                )}
            </Box>
            <Button sx={{ mb: 2 }} variant="contained" onClick={handleAddNewLog}>
                <AddIcon />
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Person</TableCell>
                            <TableCell>Destination(s)</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Left At</TableCell>
                            <TableCell>Return ETA</TableCell>
                            <TableCell>Returned At</TableCell>
                            <TableCell>Granted Hours?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offCampusLogs.map((log) => (
                            <TableRow key={log.id} onClick={() => handleEdit(log)} sx={{ cursor: isAdmin || !log.signed_in_at ? 'pointer' : '' }}>
                                <TableCell>
                                    {isAdmin || !log.signed_in_at ? (
                                        <Typography color="primary">{log.nickname}</Typography>
                                    ) : (
                                        <Typography color="black">{log.nickname}</Typography>
                                    )}
                                </TableCell>
                                <TableCell>{log.destination}</TableCell>
                                <TableCell>{log.phone_number}</TableCell>
                                <TableCell>{formatTime24To12(log.signed_out_at)}</TableCell>
                                <TableCell>{formatTime24To12(log.return_expected_at)}</TableCell>
                                <TableCell>{formatTime24To12(log.signed_in_at)}</TableCell>
                                <TableCell>{log.grant_hours ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {formData && (
                <Modal
                    open={!!formData}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            handleCancel();
                        }
                    }}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={modalStyle}>
                        {!isAdmin && (
                            <IconButton
                                onClick={() => setFormData(null)}
                                sx={{ position: 'absolute', right: 2, top: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}

                        <Autocomplete
                            multiple
                            options={eligibleStudents}
                            getOptionLabel={(option) => option.nickname}
                            renderInput={(params) => (
                                <TextField {...params} label="Select People" error={!!formErrors.student_ids} helperText={formErrors.student_ids} />
                            )}
                            value={eligibleStudents.filter(student => formData.student_ids.includes(student.id))}
                            onChange={handleStudentsChange}
                        />

                        {(isAdmin || (!isAdmin && !formData.id)) && (
                            <>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    options={destinations.map((destination) => destination.name)}
                                    value={formData.destination}
                                    onChange={handleDestinationChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Destination(s)"
                                            margin="normal"
                                            error={!!formErrors.destination}
                                            helperText={formErrors.destination}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    freeSolo
                                    options={eligibleStudents.filter(student => formData.student_ids.includes(student.id) && student.phone_number)}
                                    getOptionLabel={(option) => {
                                        // Check if option is an object (from options) or a free-form string and format accordingly
                                        if (typeof option === 'string') return option;
                                        return `${option.nickname}: ${option.phone_number}`;
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Phone Number" error={!!formErrors.phone_number} helperText={formErrors.phone_number} />
                                    )}
                                    value={
                                        // Attempt to find a matching student or use the current formData.phone_number if no match found
                                        formData.phone_number ? eligibleStudents.find(student => student.phone_number === formData.phone_number) || '' : ''
                                    }
                                    onChange={handlePhoneNumberChange}
                                    onBlur={(event) => {
                                        // Update the phone number in formData when the TextField loses focus
                                        setFormData(prevFormData => ({
                                            ...prevFormData,
                                            phone_number: event.target.value // Use the current input value
                                        }));
                                    }}
                                />

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        label="Return ETA"
                                        name="return_expected_at"
                                        type="time"
                                        value={formData.return_expected_at}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        error={!!formErrors.return_expected_at}
                                        helperText={formErrors.return_expected_at}
                                    />
                                    {formData.return_expected_at && (
                                        <IconButton
                                            onClick={() => setFormData({ ...formData, return_expected_at: '' })}
                                            size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </>
                        )}

                        {isAdmin && (
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        label="Left At"
                                        name="signed_out_at"
                                        type="time"
                                        value={formData.signed_out_at}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        error={!!formErrors.signed_out_at}
                                        helperText={formErrors.signed_out_at}
                                    />
                                    {formData.signed_out_at && (
                                        <IconButton
                                            onClick={() => setFormData({ ...formData, signed_out_at: '' })}
                                            size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        label="Returned At"
                                        name="signed_in_at"
                                        type="time"
                                        value={formData.signed_in_at || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {formData.signed_in_at && (
                                        <IconButton
                                            onClick={() => setFormData({ ...formData, signed_in_at: '' })}
                                            size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.grant_hours}
                                                onChange={(e) => setFormData({ ...formData, grant_hours: e.target.checked })}
                                            />
                                        }
                                        label="Grant Hours?"
                                    />
                                </Box>
                                <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                            sx={{ mr: 1 }}
                                        >
                                            Save
                                        </Button>
                                        <Button onClick={handleCancel}>Cancel</Button>
                                    </Box>
                                    {!!formData.id && (
                                        <Button
                                            onClick={() => handleDeleteConfirmation(formData.id)}
                                            style={{ color: '#f44336' }}
                                            variant="text"
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Box>
                            </>
                        )}
                        {!isAdmin && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 4
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            width: '100px',  // Visible width of the button
                                            height: '40px',  // Visible height of the button
                                            padding: '0',    // Ensure no extra padding
                                        }}
                                        onClick={handleSave}
                                    >
                                        {formData.id ? (formData.isAddingStudents ? 'Add Student(s)' : 'Sign In') : 'Sign Out'}
                                    </Button>
                            
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '-20px',      // Expand clickable area above the button
                                            left: '-20px',     // Expand clickable area to the left of the button
                                            right: '-20px',    // Expand clickable area to the right of the button
                                            bottom: '-20px',   // Expand clickable area below the button
                                            backgroundColor: 'transparent', // Ensure this area is invisible
                                            cursor: 'pointer', // Ensure it shows as clickable
                                        }}
                                        onClick={handleSave} // Attach the click handler to the expanded area
                                    />
                                </div>
                            </Box>                        
                        )}
                    </Box>
                </Modal>
            )}

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this log? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {alert && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={!!alert}
                    autoHideDuration={alert.autoHideDuration || 6000}
                    onClose={() => setAlert(null)}
                >
                    <Alert onClose={() => setAlert(null)} severity={alert.severity || 'error'} sx={{ width: '100%' }}>
                        {alert.message}
                    </Alert>
                </Snackbar>

            )}
        </>
    );
};

export default OffCampus;