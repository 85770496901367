import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableRow, Paper,
    Modal, Box, TextField, Button, MenuItem, FormControl, IconButton,
    InputLabel, Select, Typography, Snackbar, Alert,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableHead, FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import WarningIcon from '@mui/icons-material/Warning';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { CircularProgress } from '@mui/material';
import { formatTime24To12, formatDate, authenticatedFetch, roundToNearestTenth, isWeekend } from '../utils/utils';
import { Howl } from 'howler';
import { differenceInMinutes, isBefore, startOfDay, parse, setZone, setMilliseconds, setSeconds, setMinutes, setHours } from 'date-fns';



const Attendance = ({ isAdmin }) => {
    const stickyHeaderStyle = {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
    };

    const params = useParams();
    const now = new Date();

    const [reloadTime, setReloadTime] = useState('');
    const [students, setStudents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(now);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        student: null,
        date: null,
        signed_in_at: '',
        signed_out_at: '',
        absence_reason: 'present',
        absence_reason_other_description: '',
        hours_credited: 0
    });
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [persistentAlert, setPersistentAlert] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [lastFetchTime, setLastFetchTime] = useState(null);
    const [showViolationDialog, setShowViolationDialog] = useState(false);
    const [violationType, setViolationType] = useState('');
    const [calendarInfo, setCalendarInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const currentDayIsSelected = selectedDate.toDateString() === now.toDateString();

    const successSound = new Howl({
        src: ['/success.mp3']
    });

    const failureSound = new Howl({
        src: ['/failure.mp3']
    });

    useEffect(() => {
        if (params.date) {
            // Parse the date string assuming it's in YYYY-MM-DD format
            const parsedDate = parse(params.date, 'yyyy-MM-dd', new Date());
            
            // Set to start of day in local time
            const startOfDayDate = startOfDay(parsedDate);
            
            // Set the time to 00:00:00.000 explicitly
            const midnightDate = setMilliseconds(setSeconds(setMinutes(setHours(startOfDayDate, 0), 0), 0), 0);
            
            setSelectedDate(midnightDate);
        }
    }, [params.date]);

    const playSound = (sound) => {
        if (!isAdmin) {  // Only play sounds for non-admin users
            sound.play();
        }
    };

    const clearField = (field) => {
        setFormData({ ...formData, [field]: '' });
    };

    const fetchStudents = useCallback(async () => {
        const response = await authenticatedFetch(`/students/with-attendance-data/${selectedDate.toISOString()}`);
        if (!response.ok) throw new Error('Failed to fetch students');

        setStudents(await response.json());
        setLastFetchTime(new Date());
    }, [selectedDate]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setIsLoading(true);

            try {
                await fetchStudents();
                const calendarResponse = await authenticatedFetch(`/calendar/for-day/${selectedDate.toISOString().split('T')[0]}`);
                if (calendarResponse.ok) {
                    setCalendarInfo(await calendarResponse.json());
                } else {
                    throw new Error('Failed to fetch calendar information');
                }
            } catch (err) {
                setAlert({ message: err.message });
            } finally {
                setLoading(false);
                setIsLoading(false);
            }
        }
        fetchData();
    }, [selectedDate, fetchStudents]);

    useEffect(() => {
        if (!isAdmin) {
            const msUntilTomorrow = () => {
                const now = new Date();
                const tomorrow = new Date(now);
                tomorrow.setHours(25, 0, 0, 0);
                setReloadTime(tomorrow.toISOString());
                return tomorrow.getTime() - now.getTime();
            };

            const updateToDate = () => {
                window.location.reload();
            };

            const timeout = setTimeout(updateToDate, msUntilTomorrow());

            return () => {
                setReloadTime('');
                clearTimeout(timeout);
            }
        }
    }, [isAdmin]);

    useEffect(() => {
        // Only set up the interval if the current day is selected, the user is not an admin, and the modal is not open
        if (currentDayIsSelected && !isAdmin && !modalOpen) {
            const intervalId = setInterval(() => {
                //update the selected date to the current time and refresh the attendance records
                setSelectedDate(new Date());
            }, 1000 * 60 * 6); // Update every 6 minutes
    
            // Cleanup the interval on component unmount or when conditions change
            return () => clearInterval(intervalId);
        }
    }, [isAdmin, modalOpen, currentDayIsSelected]);

    const getSchoolStatus = () => {
        if (isWeekend(selectedDate)) {
            return { isSchoolDay: false, message: "No School: Weekend" };
        }
        
        if (calendarInfo) {
            const holiday = calendarInfo.holidays.find(h => h.date === selectedDate.toISOString().split('T')[0]);
            if (holiday) {
                return { isSchoolDay: false, message: `No School: ${holiday.name}` };
            }
            
            const selectedDateObj = new Date(selectedDate);
            const firstDay = new Date(calendarInfo.first_day);
            const lastDay = new Date(calendarInfo.last_day);
            
            if (selectedDateObj < firstDay || selectedDateObj > lastDay) {
                return { isSchoolDay: false, message: "No School: Summer Break" };
            }
        }
        else {
            return { isSchoolDay: false, message: "No School: Summer Break" };
        }
        
        return { isSchoolDay: true, message: "" };
    };

    const categorizedStudents = () => {
        const categories = {
            "Not Here Yet": [],
            "Here": [],
            "Off Campus": [],
            "Signed Out": [],
            "Absent": []
        };

        students.forEach(student => {
            if (student.is_off_campus) {
                categories["Off Campus"].push(student);
            } else if (student.attendance) {
                if (student.attendance.absence_reason === 'present') {
                    if (student.attendance.signed_out_at) {
                        categories["Signed Out"].push(student);
                    } else {
                        categories["Here"].push(student);
                    }
                }
                else {
                    categories["Absent"].push(student);
                }
            } else {
                categories["Not Here Yet"].push(student);
            }
        });

        return categories;
    };

    const currentTime = () => {
        return new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
    }

    const handleCellClick = (student) => {
        const attendance = student.attendance;

        const defaultFormData = {
            student: student,
            date: formatDate(selectedDate),
            signed_in_at: currentTime(),
            signed_out_at: '',
            absence_reason: 'present',
            absence_reason_other_description: '',
            hours_credited: 0,
            violations: student.violations
        }

        if (attendance) {
            if (!isAdmin && attendance.signed_in_at && !attendance.signed_out_at) {
                setFormData({
                    ...defaultFormData,
                    ...attendance,
                    signed_out_at: currentTime()
                })
            }
            else {
                setFormData({
                    ...defaultFormData,
                    ...attendance
                })
            }
        }
        else {
            setFormData(defaultFormData);
        }

        setFormErrors({});
        setModalOpen(true);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;

        // If the absence reason is changed, set the sign in and out times accordingly
        if (name === 'absence_reason') {
            if (value === 'present' && formData.absence_reason !== 'present') {
                setFormData({ ...formData, absence_reason: value, signed_in_at: currentTime(), signed_out_at: '' });
            }
            else if (value !== 'present' && formData.absence_reason === 'present') {
                setFormData({ ...formData, absence_reason: value, signed_in_at: '', signed_out_at: '' });
            }
            else {
                setFormData({ ...formData, [name]: value });
            }
        }
        else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.signed_in_at && formData.absence_reason === "present") errors.signed_in_at = "Sign-in time is required for students that aren't absent.";
        if (!formData.signed_in_at && formData.signed_out_at) errors.signed_out_at = "Can't sign out without signing in first.";
        if (formData.signed_in_at && formData.absence_reason !== "present") errors.signed_in_at = "Sign-in time is not allowed for absent students.";
        if (formData.signed_out_at && formData.absence_reason !== "present") errors.signed_out_at = "Sign-out time is not allowed for absent students.";
        if (formData.signed_in_at && formData.signed_out_at && formData.signed_in_at > formData.signed_out_at) errors.signed_out_at = "Sign-out time must be after sign-in time.";
        if (!isAdmin && formData.signed_out_at && formData.signed_in_at && formData.student.is_off_campus) errors.general = "Go to the Off-Campus page to sign in before you sign out for the day.";
        if (formData.hours_credited < 0 || formData.hours_credited > 24) errors.hours_credited = "Hours credited must be between 0 and 24";

        return errors;
    };

    const saveAttendance = async (e) => {
        e.preventDefault();

        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            playSound(failureSound);
            return;
        }

        setLoading(true);

        try {
            const response = await authenticatedFetch(`/attendance/record/${formData.student.id}/${formData.date}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setModalOpen(false);
                setPersistentAlert(null);
                setSelectedDate(currentDayIsSelected ? new Date() : new Date(selectedDate));
                let message = isAdmin ? 'Attendance record saved successfully' : (formData.signed_out_at ? 'Signed out successfully' : 'Signed in successfully');
                setAlert({
                    message: message,
                    severity: 'success',
                    autoHideDuration: 3000
                });
                playSound(successSound);
            } else {
                throw new Error('Failed to save attendance record');
            }
        } catch (error) {
            setAlert({ message: 'Failed to save attendance record', severity: 'error', autoHideDuration: 3000 });
            console.error('Error saving attendance record:', error);
            playSound(failureSound);
        } finally {
            setLoading(false);
        }
    };

    const deleteAttendance = async () => {
        setLoading(true);
        try {
            const response = await authenticatedFetch(`/attendance/record/${formData.student.id}/${formData.date}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                setSelectedDate(currentDayIsSelected ? new Date() : new Date(selectedDate));// Refresh attendance records
                setModalOpen(false);
            } else {
                throw new Error('Failed to delete attendance record');
            }
        } catch (error) {
            setAlert({ message: 'Failed to delete attendance record' });
            console.error('Error deleting attendance record:', error);
        } finally {
            setLoading(false);
            setShowDeleteConfirm(false);
        }
    };

    // Modal style
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const handleSelectedDateChange = (date) => {
        setOpenDatePicker(false);
        setSelectedDate(date);
    };

    const renderTableBody = () => {
        if (currentDayIsSelected) {
            return renderGroupedRows();
        } else {
            return renderStandardRows(students);
        }
    };


    const renderGroupedRows = () => (
        Object.entries(categorizedStudents()).map(([category, students]) => (
            <React.Fragment key={category}>
                <TableRow key={category}>
                    <TableCell colSpan={isAdmin ? 7 : 6} style={{ backgroundColor: '#f0f0f0' }}>{category} ({students.length})</TableCell>
                </TableRow>
                {renderStandardRows(students)}
            </React.Fragment>
        ))
    )

    const renderPersonalDaysUsed = (student) => {
        if (currentDayIsSelected || isAdmin) {
            return (
                <span style={{ color: student.unexcused_absences >= student.unexcused_absences_allowed ? 'red' : 'black' }}>
                    {student.unexcused_absences} / {student.unexcused_absences_allowed}
                </span>
            )
        }
        else {
            return '—';
        }
    }

    const absenceReasonLabel = (absence_reason) => {
        switch (absence_reason) {
            case 'sick':
                return 'Sick';
            case 'personal_day':
                return 'Personal Day';
            case 'excused':
                return 'Absent - Granted Hours';
            case 'work_study':
                return 'Absent - Work Study';
            case 'unexcused':
                return 'Absent - Hours Not Required';
            case 'present':
                return 'Present';
            case 'suspended':
                return 'Suspended - Granted Hours';
            default:
                throw new Error(`Invalid absence reason: ${absence_reason}`);
        }
    }

    const isPastWeekday = (date) => {
        const today = startOfDay(new Date());
        return isBefore(date, today) && !isWeekend(date);
        // Note: This doesn't account for holidays. You might need to add a check against a list of holidays if required.
    };

    const isIncompleteAttendance = (attendance) => {
        return !attendance || 
               (attendance.absence_reason === 'present' && 
                (!attendance.signed_in_at || (attendance.signed_in_at && !attendance.signed_out_at)));
    };

    const renderStandardRows = (students) => (
        students.map(student => (
            <TableRow
                key={student.id}
                sx={{
                    cursor: 'pointer',
                    backgroundColor: (isPastWeekday(selectedDate) && isIncompleteAttendance(student.attendance)) ? '#FFCCCB' : 'inherit'
                }}
                onClick={() => handleCellClick(student)}
            >
                <TableCell color="primary">
                    <Typography color="primary">
                        {student.nickname}
                    </Typography>
                </TableCell>
                <TableCell>
                    {student.attendance && student.attendance.signed_in_at ? formatTime24To12(student.attendance.signed_in_at) : '—'}
                </TableCell>
                <TableCell>
                    {student.attendance && student.attendance.signed_out_at ? formatTime24To12(student.attendance.signed_out_at) : '—'}
                </TableCell>
                <TableCell>
                    {student.attendance && student.attendance.absence_reason !== 'present' ? absenceReasonLabel(student.attendance.absence_reason) : '—'}
                </TableCell>
                {isAdmin && (
                    <TableCell>
                        {student.attendance ? student.attendance.hours_credited : '—'}
                    </TableCell>
                )}
                <TableCell>
                <span style={{
                        color: 'white',
                        backgroundColor: (student.attendance && student.attendance.hours_earned >= student.daily_hours_required) 
                                        ? '#32CD32' // Green background if hours earned meet or exceed daily requirement
                                        : 'black',  // Black background if hours earned do not meet the daily requirement
                        padding: '2px 4px',
                        borderRadius: '4px',
                    }}>
                        {roundToNearestTenth((student.attendance && student.attendance.hours_earned) || 0)}
                    </span>
                    &nbsp;of&nbsp;
                    <span style={{
                        backgroundColor: student.warning_status === 'warning' ? '#FFD700' 
                                        : student.warning_status === 'violation' ? 'red' 
                                        : student.warning_status === '' ? '#32CD32' // LimeGreen for appropriate green background
                                        : 'transparent',
                        color: 'white',
                        padding: '2px 4px',
                        borderRadius: '4px'
                    }}>
                        {roundToNearestTenth(student.daily_hours_required)}
                    </span>
                </TableCell>
                <TableCell onClick={(e) => {
                    if (isAdmin) {
                        e.stopPropagation();
                        if (student.unexcused_absences) setSelectedDate(new Date(student.unexcused_absences));
                    }
                }}>
                    {renderPersonalDaysUsed(student)}
                </TableCell>
            </TableRow>
        ))
    )

    useEffect(() => {
        let timeoutId;
        let intervalId;

        if (modalOpen && !isAdmin) {
            timeoutId = setTimeout(() => {
                setPersistentAlert({
                    message: 'You forgot to sign in or out!',
                    severity: 'warning'
                });

                // Only start playing the sound if there are no warning messages
                if (!formData.student.warning_status && (!formData.student.violations || formData.student.violations.length === 0)) {
                    intervalId = setInterval(() => {
                        playSound(failureSound);
                    }, 1000);
                }

            }, 5000); // 5 seconds timeout
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
            if (intervalId) clearInterval(intervalId);
            setPersistentAlert(null);
        };
    }, [modalOpen, isAdmin, formData.student]);

    // Modify the setModalOpen function to clear the interval when the modal is closed
    const closeModal = () => {
        setModalOpen(false);
        setPersistentAlert(null); // Clear the persistent alert when closing the modal
    };

    const updateHoursEarned = useCallback(() => {
        if (!lastFetchTime || !currentDayIsSelected) return;

        const now = new Date();
        if (now.getHours() >= 16) return; // Stop updating at 4 PM

        setStudents(prevStudents => 
            prevStudents.map(student => {
                if (student.attendance && student.attendance.signed_in_at && !student.attendance.signed_out_at) {
                    const minutesSinceLastFetch = differenceInMinutes(now, lastFetchTime);
                    const additionalHours = minutesSinceLastFetch / 60;
                    return {
                        ...student,
                        attendance: {
                            ...student.attendance,
                            hours_earned: roundToNearestTenth(student.attendance.hours_earned + additionalHours)
                        }
                    };
                }
                return student;
            })
        );

        setLastFetchTime(now);
    }, [lastFetchTime, currentDayIsSelected]);

    useEffect(() => {
        let intervalId;
        if (currentDayIsSelected && !isAdmin) {
            intervalId = setInterval(() => {
                updateHoursEarned();
            }, 60000); // Update every minute
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [currentDayIsSelected, isAdmin, updateHoursEarned]);

    const reportViolation = async (violationType) => {
        if (!formData.student) return;
    
        let violationData;
        if (violationType === "didntSignIn") {
            violationData = {
                student_id: formData.student.id,
                law_code: "500.1.4",
                description: "Didn't Sign In"
            };
        } else if (violationType === "didntNotify") {
            violationData = {
                student_id: formData.student.id,
                law_code: "500.1.3",
                description: "Didn't Notify the School when Absent"
            };
        } else {
            return;
        }
    
        try {
            const response = await authenticatedFetch('/violations', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(violationData)
            });
    
            if (response.ok) {
                setAlert({
                    message: 'Violation reported successfully',
                    severity: 'success',
                    autoHideDuration: 3000
                });
                playSound(successSound);
            } else {
                throw new Error('Failed to report violation');
            }
        } catch (error) {
            setAlert({
                message: 'Failed to report violation',
                severity: 'error',
                autoHideDuration: 3000
            });
            console.error('Error reporting violation:', error);
            playSound(failureSound);
        } finally {
            setShowViolationDialog(false);
            setViolationType('');
        }
    };
    
    const handleReportViolation = () => {
        setShowViolationDialog(true);
    };

    const calculateTotalFines = (violations) => {
        return violations ? violations.length : 0;
    };
    
    return (
        <>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'bottom' }}>
                <Typography variant="h5" gutterBottom>
                    {format(selectedDate, 'eeee, MMMM do, yyyy')}
                </Typography>
                {isAdmin && (<Button sx={{ mb: 2 }} onClick={() => setOpenDatePicker(true)}>Change Day</Button>)}
                {openDatePicker && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            open={openDatePicker}
                            value={selectedDate}
                            onChange={handleSelectedDateChange}
                            onClose={() => setOpenDatePicker(false)}
                            views={['day']}
                            shouldDisableDate={(date) => [0, 6].includes(date.getDay())}
                        />
                    </LocalizationProvider>
                )}
            </Box>
            {isLoading ? (
                <>
                </>
            ) : getSchoolStatus().isSchoolDay ? (
                <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
                    <Table sx={{ tableLayout: 'auto', borderCollapse: 'separate', borderSpacing: 0 }} aria-label="attendance table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={stickyHeaderStyle}>Name</TableCell>
                                <TableCell sx={stickyHeaderStyle}>In</TableCell>
                                <TableCell sx={stickyHeaderStyle}>Out</TableCell>
                                <TableCell sx={stickyHeaderStyle}>Absent?</TableCell>
                                {isAdmin && (
                                    <TableCell sx={stickyHeaderStyle}>Hours Credited</TableCell>
                                )}
                                <TableCell sx={stickyHeaderStyle}>Hours</TableCell>
                                <TableCell sx={stickyHeaderStyle}>Personal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderTableBody()}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h4" align="center" sx={{ mt: 4 }}>
                    {getSchoolStatus().message}
                </Typography>
            )}

            {formData.student && (
                <Modal
                    open={modalOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            closeModal();
                        }
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        {isAdmin ? (
                            <>
                                <Typography variant="h6" sx={{ textAlign: "center" }}>
                                    {formData.student.nickname}
                                </Typography>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            name="signed_in_at"
                                            label="Signed In Time"
                                            type="time"
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.signed_in_at}
                                            onChange={handleEditChange}
                                            error={!!formErrors.signed_in_at}
                                            helperText={formErrors.signed_in_at}
                                        />
                                        <IconButton onClick={() => clearField('signed_in_at')}><ClearIcon /></IconButton>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            name="signed_out_at"
                                            label="Signed Out Time"
                                            type="time"
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.signed_out_at}
                                            onChange={handleEditChange}
                                            error={!!formErrors.signed_out_at}
                                            helperText={formErrors.signed_out_at}
                                        />
                                        <IconButton onClick={() => clearField('signed_out_at')}><ClearIcon /></IconButton>
                                    </Box>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="absence-reason-label">Absence Reason</InputLabel>
                                        <Select
                                            labelId="absence-reason-label"
                                            id="absence_reason"
                                            name="absence_reason"
                                            value={formData.absence_reason}
                                            label="Absence Reason"
                                            onChange={handleEditChange}
                                            error={!!formErrors.absence_reason}
                                        >
                                            <MenuItem value="present">{absenceReasonLabel('present')}</MenuItem>
                                            <MenuItem value="sick">{absenceReasonLabel('sick')}</MenuItem>
                                            <MenuItem value="personal_day">{absenceReasonLabel('personal_day')}</MenuItem>
                                            <MenuItem value="excused">{absenceReasonLabel('excused')}</MenuItem>
                                            <MenuItem value="work_study">{absenceReasonLabel('work_study')}</MenuItem>
                                            <MenuItem value="unexcused">{absenceReasonLabel('unexcused')}</MenuItem>
                                            <MenuItem value="suspended">{absenceReasonLabel('suspended')}</MenuItem>
                                        </Select>
                                        {formErrors.absence_reason && <FormHelperText style={{ color: 'red' }}>{formErrors.absence_reason}</FormHelperText>}
                                    </FormControl>
                                    {formData.absence_reason !== 'present' && (
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            name="absence_reason_other_description"
                                            label="Absence Details"
                                            value={formData.absence_reason_other_description}
                                            onChange={handleEditChange}
                                        />
                                    )}
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        name="hours_credited"
                                        label="Hours Credited"
                                        value={formData.hours_credited}
                                        onChange={handleEditChange}
                                    />
                                    <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={saveAttendance}
                                                sx={{ mr: 1 }}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                        <Box>
                                            {!formData.id && (
                                                <Button
                                                    onClick={() => handleReportViolation()}
                                                    style={{ color: '#f44336', marginRight: '8px' }}
                                                    variant="text"
                                                >
                                                    REPORT VIOLATION
                                                </Button>
                                            )}

                                            {formData.id && (
                                                <Button
                                                    onClick={() => setShowDeleteConfirm(true)}
                                                    style={{ color: '#f44336' }}
                                                    variant="text"
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={{ position: 'relative' }}>
                                    <IconButton
                                        onClick={closeModal}
                                        sx={{ position: 'absolute', right: 0, top: 0 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" sx={{ textAlign: "center", paddingTop: '20px' }}>
                                        {formData.student.nickname}
                                    </Typography>
                                    {formData.student.warning_status && (
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            backgroundColor: formData.student.warning_status === 'warning' ? '#FFD700' : 'red', 
                                            color: formData.student.warning_status === 'warning' ? '#000000' : '#FFFFFF', // Change text color
                                            padding: '12px', 
                                            borderRadius: '4px', 
                                            mb: 2, 
                                            mt: 2, 
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' 
                                        }}>
                                            {formData.student.warning_status === 'warning' && <WarningIcon sx={{ mr: 1 }} />}
                                            {formData.student.warning_status === 'violation' && <ReportProblemIcon sx={{ mr: 1 }} />}
                                            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                                {formData.student.warning_status === 'warning'
                                                    ? `You're behind on attendance and must now attend ${Math.floor(formData.student.daily_hours_required)} hours and ${Math.round((formData.student.daily_hours_required % 1) * 60)} minutes per day.`
                                                    : `You're in violation of attendance law 500.1.2 and must now attend ${Math.floor(formData.student.daily_hours_required)} hours and ${Math.round((formData.student.daily_hours_required % 1) * 60)} minutes per day.`}
                                            </Typography>
                                        </Box>
                                    )}
                                    {formData.student.violations && formData.student.violations.length > 0 && (
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            backgroundColor: '#FFD700', // Keep the yellow background
                                            color: '#000000', // Change text color to black for better contrast
                                            padding: '12px', 
                                            borderRadius: '4px', 
                                            mb: 2, 
                                            mt: 2, 
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' 
                                        }}>
                                            <ReportProblemIcon sx={{ mr: 1, color: '#000000' }} />
                                            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                                You have ${calculateTotalFines(formData.student.violations)} in attendance fines. See an Attendance Clerk for more information.
                                            </Typography>
                                        </Box>
                                    )}
                                    {formErrors.general && (
                                        <Typography variant="h6" sx={{ textAlign: "center" }} color="red">
                                            {formErrors.general}
                                        </Typography>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 4
                                    }}
                                >
                                    {(!formData.student.attendance || (formData.student.attendance.absence_reason === 'present' && (!formData.student.attendance.signed_in_at || !formData.student.attendance.signed_out_at))) && (
                                        <div
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: '140px',  // Matching the overall clickable area width from the off-campus modal
                                                height: '80px',  // Matching the overall clickable area height from the off-campus modal
                                                cursor: 'pointer' // Ensure it shows as clickable
                                            }}
                                            onClick={saveAttendance} // Bind the click event to the whole container
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    position: 'absolute',
                                                    top: '20px',  // Offset by half the increased height to center the button visually
                                                    left: '20px', // Offset by half the increased width to center the button visually
                                                    width: '100px',  // The visible width of the button
                                                    height: '40px',  // The visible height of the button
                                                    padding: '0',    // Keep the padding default
                                                    zIndex: 2,       // Ensure the button is above the clickable area
                                                }}
                                            >
                                                {formData.student.attendance && formData.student.attendance.signed_in_at && !formData.student.attendance.signed_out_at ? 'Sign Out' : 'Sign In'}

                                            </Button>
                                        </div>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        position: 'absolute',
                                        bottom: 8,
                                        right: 8,
                                    }}
                                >
                                </Box>
                            </>
                        )}
                    </Box>
                </Modal>
            )}

            <Dialog
                open={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this attendance record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteConfirm(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteAttendance} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showViolationDialog}
                onClose={() => setShowViolationDialog(false)}
                aria-labelledby="violation-dialog-title"
                aria-describedby="violation-dialog-description"
            >
                <DialogTitle id="violation-dialog-title">{"Report Violation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="violation-dialog-description">
                        Please select the type of violation:
                    </DialogContentText>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Button 
                            onClick={() => reportViolation("didntSignIn")} 
                            style={{ color: '#f44336' }}
                            variant="text"
                        >
                            Didn't Sign In
                        </Button>
                        <Button 
                            onClick={() => reportViolation("didntNotify")} 
                            style={{ color: '#f44336' }}
                            variant="text"
                        >
                            Didn't Notify the School
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowViolationDialog(false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {persistentAlert && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={!!persistentAlert}
                >
                    <Alert severity={persistentAlert.severity} sx={{ width: '100%' }}>
                        {persistentAlert.message}
                    </Alert>
                </Snackbar>
            )}

            {alert && (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={!!alert}
                    autoHideDuration={alert.autoHideDuration || 6000}
                    onClose={() => setAlert(null)}
                >
                    <Alert onClose={() => setAlert(null)} severity={alert.severity || 'error'} sx={{ width: '100%' }}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            )}

            <Typography sx={{ mt: 1, fontSize: 10 }} color="gray">
                Will reload at {reloadTime}
            </Typography>
        </>
    );
};

export default Attendance;